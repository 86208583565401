export { wrapRootElement } from "./src/RootElement";
export const onRouteUpdate = ({ location, prevLocation }) => {
	if (typeof window !== "undefined") {
		// Retrieve the last valid location from session storage
		const lastValidLocation =
			window.sessionStorage.getItem("lastValidLocation");

		// Update the lastVisitedPage with prevLocation if it exists, otherwise use lastValidLocation
		if (prevLocation) {
			window.sessionStorage.setItem("lastVisitedPage", prevLocation.pathname);
		} else if (lastValidLocation) {
			window.sessionStorage.setItem("lastVisitedPage", lastValidLocation);
		}

		// Update the lastValidLocation with the current location's pathname
		window.sessionStorage.setItem("lastValidLocation", location.pathname);
	}
};
